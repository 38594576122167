import React from "react";
import QDetectSection from "../../components/Projects/Qdetect/qdetect.js";
import Layout from "../../components/UI/layout";
import SEO from "../../components/UI/seo";
import { graphql } from "gatsby";

const firmaDigitalPage = () => (    
    <Layout>
        <SEO title="Codeicus | qdetct" />
        <QDetectSection/>
    </Layout>
)

export default firmaDigitalPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;