import React from 'react';
import * as styles from '../projects.module.scss'
import Tabs from  '../Tabs/tabs';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import Process from '../Process/process';
import Square from '../Square/square';
import Qdetect2x from '../../../images/Home/qd@2x.png';

const QDetectSection = () => {

    const {t} = useTranslation();

    return (
        <section className={styles.projectSection}>
            <div className={styles.containProject}>
                <div className={styles.containBody}>
                    <div className={styles.contentInfoLeft}>
                        
                        <Tabs
                         active = 'Qdetect'/>

                        <Square
                            title = {t("projectsPage.qDetect.square.title")}
                            subtitle= {t("projectsPage.qDetect.square.subtitle")}
                            description = {t("projectsPage.qDetect.square.description")}
                            list = {[t("projectsPage.qDetect.square.list.0"),
                                     t("projectsPage.qDetect.square.list.0")
                               ]}                                                   
                            color = '#69c95c'
                            image = {Qdetect2x}
                        /> 
                    </div>
                    <div className={styles.contentInfoRight}>    
                        <Process 
                            number = '1'
                            title = {t("projectsPage.qDetect.process.1.title")}
                            description = {t("projectsPage.qDetect.process.1.description")}
                            list= {[t("projectsPage.qDetect.process.1.list.0"),
                                    t("projectsPage.qDetect.process.1.list.1"),
                                    t("projectsPage.qDetect.process.1.list.2"),
                                    t("projectsPage.qDetect.process.1.list.3"),
                                    t("projectsPage.qDetect.process.1.list.4")
                                ]}
                        />
                        <Process 
                            number = '2'
                            title = {t("projectsPage.qDetect.process.2.title")}
                            description = {t("projectsPage.qDetect.process.2.description")}
                            list= {[t("projectsPage.qDetect.process.2.list.0"),
                                    t("projectsPage.qDetect.process.2.list.1"),
                                    t("projectsPage.qDetect.process.2.list.2")
                                ]}
                        />
                        <Process 
                            number = '3'
                            title = {t("projectsPage.qDetect.process.3.title")}
                            description = {t("projectsPage.qDetect.process.3.description")}
                            list= {[]}
                        />
                        <Process 
                            number = '4'
                            title = {t("projectsPage.qDetect.process.4.title")}
                            description = {t("projectsPage.qDetect.process.4.description")}
                            list= {[t("projectsPage.qDetect.process.4.list.0"),
                                    t("projectsPage.qDetect.process.4.list.1"),
                                    t("projectsPage.qDetect.process.4.list.2")
                                ]}
                        />
                    </div>
                </div>
            </div>
		</section>
    );
};
export default QDetectSection;